 <template>
  <div>
    <CModal color="primary" :show="showModal" size="lg">
      <template v-slot:header>
        <h5 class="modal-title">Product Details</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(showModal)"
        >
          ×
        </button>
      </template>
      <div class="text-center" v-if="product">
         <img height="300px" width="300px" class="rounded mx-auto d-block" style="object-fit: cover;" :src="product ? product.image : null" />
      </div>
     
      <p class="mt-5">
        Name:
        {{ product ? product.name : null }}.
      </p>
      <p>
        Model:
        {{ product ? product.product_model.name : null }}.
      </p>
      <p>
        Group:
        {{ product ? product.product_group.name : null }}.
      </p>
      <p>
        Brand:
        {{ product ? product.brand.name : null }}.
      </p>
      <p>
        Category:
        {{ product ? product.product_category.name : null }}.
      </p>
      
      <p >
        Created by:
        {{ product ? product.created_by.username : null }}.
      </p>
      <p >
        Updated by:
        {{ product ? product.updated_by.username : null }}.
      </p>
      <p >
        Description:
        {{ product ? product.description : null }}.
      </p>

      <template v-slot:footer>
        <CButton color="primary" @click="closeModal(showModal)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductView",
  computed: {
    ...mapState({
      showModal: (state) => state.products.showModal,
      product: (state) => state.products.showProduct,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("products/SHOW_MODAL", {
        bool: bool,
        product: null,
      });
    },
  },
};
</script>